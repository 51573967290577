const ARRROUTER = [
  // {
  //   path: "/maplibre",
  //   name: "AppMaplibre",
  //   meta: { title: "Maplibre" },
  //   component: () => import("./pages/MapPortal.vue"),
  // },
  {
    path: "/auto",
    name: "AppMappano",
    meta: { title: "TOUR TỰ ĐỘNG" },
    component: () => import("./pages/MapPano.vue"),
  },
  {
    path: "/tour",
    name: "AppTourVr",
    meta: { title: "TOUR 360" },
    component: () => import("./pages/TripDemo.vue"),
  },
];

export default function (path) {
  return ARRROUTER.map((item) => {
    if (path) item.path = "/" + path + item.path;
    return item;
  });
}
